
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import { IBranch, IBranchListRequest } from "@/types/branch";
import { IUserListRequest } from "@/types/user";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { IProduct, IProductListRequest } from "@/types/product";
import { IUser } from "@/types/user";
import { IStock, IStockListRequest } from "@/types/stock";
import { ProductShipment } from "@/models/product_shipment";
import {
  ILotShipment,
  IShipment,
  IShipmentEditFormDisp,
  IShipmentUpdateRequest
} from "@/types/shipment";
import { LotShipment, ShipmentRequest } from "@/models/shipment";
import { IReceivedOrder } from "@/types/received_order";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //shipment
  @Action("shipment/adminGet")
  public getShipment!: (shipment_id: number) => Promise<boolean>;

  @Action("shipment/adminUpdate")
  public update!: (data: {
    shipment_id: number;
    params: IShipmentUpdateRequest;
  }) => Promise<boolean>;

  @Action("shipment/adminDelete")
  public deleteShipment!: (shipment_id: number) => Promise<boolean>;

  @Getter("shipment/single")
  public shipment!: IShipment;

  @Mutation("shipment/clear")
  public clear!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGetList")
  public getBranches!: (params: IBranchListRequest) => Promise<boolean>;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //received_order
  @Action("received_order/adminGet")
  public getReceivedOrder!: (received_order_id: number) => Promise<boolean>;

  @Action("received_order/adminReceivedOrderIsShipmentComplete")
  public adminReceivedOrderIsShipmentComplete!: (
    received_order_id: number
  ) => Promise<boolean>;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Action("user/adminGetStaffList")
  public getStaffs!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/selectItemStaff")
  public staffList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //stock
  @Action("stock/adminGetStockSegments")
  public getStockSegments!: (request: IStockListRequest) => Promise<boolean>;

  @Getter("stock/single")
  public stock!: IStock;

  @Getter("stock/find")
  public findStock!: (stock_id: number) => IStock;

  @Getter("stock/selectWarehouseWithProducts")
  public WarehouseWithProducts!: (
    product_id: number,
    customer_id: number,
    stock_id?: number,
    edit?: boolean
  ) => ISelectItem[];

  @Getter("stock/warehouseFilterdSelectItem")
  public warehouseFilterdSelectItem!: (
    product_id: number,
    customer_id: number,
    warehouse_id: number,
    stock_id?: number,
    edit?: boolean
  ) => ISelectItem[];

  @Mutation("stock/clear")
  public clearStock!: () => void;

  // note
  @Action("note/adminUpdateNote")
  public updateNote!: (
    notes: [
      {
        id: number | null;
        content: string | null;
      }
    ]
  ) => Promise<boolean>;

  //パラメータ定義
  public params: IShipmentUpdateRequest = new ShipmentRequest();

  //複数Lot用配列
  public lot_shipments: ILotShipment[][] = [];

  //変数定義
  public shipment_status = Vue.prototype.$shipmentStatus;
  public shipment_id = 0;
  public user_id = 0;
  public received_order_no = "";
  public received_order_destination = "";
  public isWarehouse: boolean[][] = [];
  public max_quantity: number[][] = [];
  public unit: string[] = [];
  public customer_pn: (string | null)[] = [];
  public productOptions: ISelectItem[] = [];
  public lotOptions: number[][] = [];
  public warehouseOptions: ISelectItem[] = [];
  public received_order_quantity: number[] = [];
  public remaining_shipment_quantity: number[] = [];
  public notes: [
    {
      id: number | null;
      content: string | null;
    }
  ] = [
    {
      id: 0,
      content: null
    }
  ];
  public edit = true;
  public valid = true;
  public valid_product_shipment = true;
  public lazy = false;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid_years_disp = false;
  public disp = false;
  public isSubmit = false;
  public menu = {
    product: false,
    branch: false
  };

  public rules = {
    shipment_date: [(v: string) => !!v || "出荷日は必須です"],
    customer: [(v: string) => !!v || "顧客は必須です"],
    received_order: [(v: number) => !!v || "顧客注文番号は必須です"],
    maker: [(v: number) => !!v || "メーカは必須です"],
    product: [(v: number) => !!v || "商品は必須です"],
    warehouse: [(v: number) => !!v || "出荷倉庫は必須です"],
    remaining_shipment: [
      (v: number) => !v || v >= 0 || "残出荷数量を超えています"
    ],
    sales_staff: [(v: number) => !!v || "テクネ担当者は必須です"],
    user_id: [(v: number) => !!v || "登録者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.shipment_id = Number(this.$route.params.shipment_id) || 0;
    // this.params.user_id = this.user.id;
    this.clear();
    this.clearStock();
    this.clearUser();

    await this.getShipment(this.shipment_id);

    this.valid_years_disp = this.diffMonth(this.shipment.shipment_date) > 12;

    if (
      this.valid_years_disp &&
      this.shipment.shipment_status == this.shipment_status.shipped
    ) {
      return;
    }

    await Promise.all([
      this.getReceivedOrder(this.shipment.received_order_id),
      this.getCustomers({ per_page: 0 }),
      this.getMakers({ per_page: 0 }),
      this.getUsers({ per_page: 0 })
    ]);

    if (this.shipment.branch_id) {
      await Promise.all([
        this.getBranches({
          customer_id: this.shipment.customer_id,
          per_page: 0
        }),
        this.getStaffs({ branch_id: this.shipment.branch_id })
      ]);
      if (this.staffList.length == 0) {
        await this.getStaffs({ customer_id: this.shipment.customer_id });
      }
    }

    if (!this.shipment.branch_id) {
      await this.getStaffs({ customer_id: this.shipment.customer_id });
    }

    await this.setUpdateDefault();

    this.disp = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      (
        this.$refs.product_shipments as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setUpdateDefault() {
    this.params.authorizer = this.shipment.authorizer || 0;
    this.params.shipment_date = this.shipment.shipment_date || "";
    this.params.shipment_status = this.shipment.shipment_status || 0;
    this.params.received_order_id = this.shipment.received_order_id || 0;
    this.received_order_no = this.shipment.received_order_no || "";
    this.received_order_destination = this.received_order.destination || "ー";
    this.params.customer_id = this.shipment.customer_id || 0;
    this.params.branch_id = this.shipment.branch_id || null;
    this.params.sales_staff = this.shipment.sales_staff || 0;
    this.params.user_id = this.shipment.user_id || 0;

    //出荷商品セット
    for (let [
      index,
      shipment_edit_form_disp
    ] of this.shipment.shipment_edit_form_disp.entries()) {
      this.params.product_shipments.push(new ProductShipment());
      this.lot_shipments.push([]);
      this.max_quantity.push([]);
      this.lotOptions.push([]);
      this.isWarehouse.push([false]);
      //出荷ロットの共通部分セット
      await this.commonPartLotShipmentSet(index, shipment_edit_form_disp);
      //出荷ロットセット
      await this.lotShipmentSet(index, shipment_edit_form_disp);
    }
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.isSubmit = true;
    this.submit_dialog = false;
    let result = false;
    let count_index = 0;
    this.lot_shipments.forEach((lot_shipment, index: number) => {
      lot_shipment.forEach(detail => {
        const productShipment = new ProductShipment(
          this.params.product_shipments[index]
        );
        productShipment.lot_id = detail.lot_id;
        productShipment.buying_unit_price = Number(
          detail.buying_unit_price.toFixed(2)
        );
        productShipment.warehouse_id = detail.warehouse_id;
        productShipment.stock_id = detail.stock_id;
        productShipment.stockage_id = detail.stockage_id;
        productShipment.quantity = detail.quantity || 0;
        productShipment.amount = Number(detail.amount.toFixed(2));
        productShipment.selling_unit_amount = Number(
          detail.selling_unit_amount.toFixed(2)
        );
        productShipment.memo = detail.product_shipment_memo;
        productShipment.movement_id = detail.movement_id;
        productShipment.id = detail.product_shipment_id;
        this.params.product_shipments.push(productShipment);
      });
      count_index += 1;
    });
    this.params.product_shipments.splice(0, count_index);

    // 更新
    result = await this.update({
      shipment_id: this.shipment_id,
      params: this.params
    });

    //受注商品メモ更新
    if (result) {
      result = await this.updateNote(this.notes);
    }

    //受注ステータス自動切替
    if (result) {
      await this.adminReceivedOrderIsShipmentComplete(
        this.params.received_order_id
      );
      // ひとつ前の画面に遷移
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    let result = false;
    // 削除
    result = await this.deleteShipment(this.shipment_id);
    //受注ステータス自動切替
    if (result) {
      await this.adminReceivedOrderIsShipmentComplete(
        this.params.received_order_id
      );
      // 出荷一覧に遷移
      this.$router.push(`/admin/shipment/list`);
    }
  }

  //出荷ロットの共通部分セット
  public async commonPartLotShipmentSet(
    index: number,
    shipment_edit_form_disp: IShipmentEditFormDisp
  ) {
    const product_shipment = this.params.product_shipments[index];
    this.received_order_quantity[index] =
      shipment_edit_form_disp.received_order_quantity;
    this.remaining_shipment_quantity[index] =
      shipment_edit_form_disp.remaining_shipment_quantity;
    this.customer_pn[index] = shipment_edit_form_disp.customer_pn;
    this.unit[index] = shipment_edit_form_disp.unit;
    product_shipment.maker_id = shipment_edit_form_disp.maker_id;
    await this.makerSelected(index);
    product_shipment.product_id = shipment_edit_form_disp.product_id;
    await this.productSelected(index);
    product_shipment.customer_product_id =
      shipment_edit_form_disp.customer_product_id;
    product_shipment.selling_unit_price = Number(
      Number(shipment_edit_form_disp.selling_unit_price).toFixed(2)
    );
    product_shipment.note_id = shipment_edit_form_disp.note_id;
    if (index == 0) {
      this.notes[0].id = shipment_edit_form_disp.note_id;
      this.notes[0].content = shipment_edit_form_disp.content;
    }
    if (index != 0) {
      this.notes.push({
        id: shipment_edit_form_disp.note_id,
        content: shipment_edit_form_disp.content
      });
    }
  }

  //出荷ロットセット
  public async lotShipmentSet(
    index: number,
    shipment_edit_form_disp: IShipmentEditFormDisp
  ) {
    for (let [
      index_nest,
      back_lot_shipment
    ] of shipment_edit_form_disp.lot_shipments.entries()) {
      this.lot_shipments[index].push(new LotShipment());
      const front_lot_shipment = this.lot_shipments[index][index_nest];
      front_lot_shipment.amount = Number(back_lot_shipment.amount.toFixed(2));
      front_lot_shipment.buying_unit_price = Number(
        Number(back_lot_shipment.buying_unit_price).toFixed(2)
      );
      front_lot_shipment.lot_id = back_lot_shipment.lot_id;
      front_lot_shipment.movement_id = back_lot_shipment.movement_id;
      front_lot_shipment.product_shipment_memo =
        back_lot_shipment.product_shipment_memo;
      front_lot_shipment.product_shipment_id =
        back_lot_shipment.product_shipment_id;
      front_lot_shipment.selling_unit_amount = Number(
        back_lot_shipment.selling_unit_amount.toFixed(2)
      );
      front_lot_shipment.stockage_id = back_lot_shipment.stockage_id;
      front_lot_shipment.warehouse_id = back_lot_shipment.warehouse_id;
      await this.warehouseSelected(index, index_nest);
      front_lot_shipment.quantity = back_lot_shipment.quantity;
      front_lot_shipment.stock_id = back_lot_shipment.stock_id;
      this.max_quantity[index][index_nest] =
        this.findStock(front_lot_shipment.stock_id).quantity +
        (back_lot_shipment.quantity || 0);
    }
  }

  //リアクティブ残出荷数量計算
  public NowRemainingShipments(index: number) {
    if (this.isSubmit) return;

    let remaining_shipment_quantity = this.remaining_shipment_quantity[index];
    this.lot_shipments[index].forEach(lot_shipment => {
      remaining_shipment_quantity -= Number(lot_shipment.quantity);
    });

    return remaining_shipment_quantity;
  }

  //メーカが選択された際の挙動
  public async makerSelected(index: number) {
    await this.getProductSegments({
      maker_id: this.params.product_shipments[index].maker_id,
      per_page: 0
    });
    this.$set(
      this.productOptions,
      index,
      this.makerFilterdSelectItem(this.params.product_shipments[index].maker_id)
    );
  }

  //商品が選択された際の挙動
  public async productSelected(index: number) {
    await this.getStockSegments({
      product_id: this.params.product_shipments[index].product_id,
      per_page: 0
    });
    this.$set(
      this.warehouseOptions,
      index,
      this.WarehouseWithProducts(
        this.params.product_shipments[index].product_id,
        this.params.customer_id,
        this.stock.id,
        this.edit
      )
    );
  }

  //倉庫が選択された際の挙動
  public warehouseSelected(index: number, index_nest: number) {
    this.isWarehouse[index][index_nest] =
      !!this.lot_shipments[index][index_nest].warehouse_id;
    this.lot_shipments[index][index_nest].stock_id = 0;
    this.lot_shipments[index][index_nest].quantity = null;
    this.$set(
      this.lotOptions[index],
      index_nest,
      this.warehouseFilterdSelectItem(
        this.params.product_shipments[index].product_id,
        this.params.customer_id,
        this.lot_shipments[index][index_nest].warehouse_id,
        this.stock.id,
        this.edit
      )
    );
  }

  //ロットが選択された際の挙動
  public lotSelected(index: number, index_nest: number) {
    this.lot_shipments[index][index_nest].quantity = null;
    const lot_shipment = this.lot_shipments[index][index_nest];
    if (lot_shipment.stock_id) {
      const stock = this.findStock(lot_shipment.stock_id);
      lot_shipment.lot_id = stock.lot_id;
      lot_shipment.buying_unit_price = Number(
        stock.buying_unit_price.toFixed(2)
      );
      lot_shipment.stockage_id = stock.stockage_id;
      this.max_quantity[index][index_nest] = stock.quantity;
    }
    if (!lot_shipment.stock_id) {
      lot_shipment.lot_id = 0;
      lot_shipment.buying_unit_price = 0;
      lot_shipment.stockage_id = 0;
      this.max_quantity[index][index_nest] = 0;
    }
  }

  //仕入単価合計金額計算
  public calcBuyingAmount(index: number, index_nest: number) {
    const lot_shipment = this.lot_shipments[index][index_nest];
    lot_shipment.amount = Number(
      (lot_shipment.buying_unit_price * (lot_shipment.quantity || 0)).toFixed(2)
    );
  }

  //売単価合計金額計算
  public calcSellingAmount(index: number, index_nest: number) {
    const lot_shipment = this.lot_shipments[index][index_nest];
    lot_shipment.selling_unit_amount = Number(
      (
        this.params.product_shipments[index].selling_unit_price *
        (lot_shipment.quantity || 0)
      ).toFixed(2)
    );
  }

  //出荷ロット追加
  public appendShipmentDetail(index: number) {
    this.lot_shipments[index].push(new LotShipment());
    this.max_quantity[index].push();
    this.lotOptions[index].push();
    this.isWarehouse[index].push(false);
  }

  //出荷ロット削除
  public deleteShipmentDetail(index: number, index_detail: number) {
    this.lot_shipments[index].splice(index_detail, 1);
    this.max_quantity[index].splice(index_detail, 1);
    this.lotOptions[index].splice(index_detail, 1);
    this.isWarehouse[index].splice(index_detail, 1);
  }

  //quantityバリデーション
  public unitRule(value: number, index: number, index_nest: number) {
    if (!this.max_quantity[index][index_nest]) {
      return true;
    }
    return (
      value <= this.max_quantity[index][index_nest] ||
      "在庫以上は入力できません"
    );
  }

  //lotバリデーション
  public lotRule(v: number, index: number) {
    const countSameStockId = this.lot_shipments[index].filter(
      lot_shipment => lot_shipment.stock_id == v
    ).length;
    if (countSameStockId >= 2) {
      return "同じロットは選択できません";
    }
    return true;
  }

  //バリデーションチェック
  public updated() {
    if (
      !this.disp ||
      this.isSubmit ||
      (this.valid_years_disp &&
        this.shipment.shipment_status == this.shipment_status.shipped)
    ) {
      return;
    }

    this.$nextTick(function () {
      (
        this.$refs.product_shipments as Vue & { validate: () => boolean }
      ).validate();
    });
  }
}
